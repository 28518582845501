<template>
  <div class="orderdetails">
    <van-nav-bar :title="ordertitle" left-arrow @click-left="onClickLeft" />
    <div class="address" v-if="order.address">
      <van-cell center>
        <template #icon>
          <van-icon name="location-o"></van-icon>
        </template>
        <template #title>
          <span>{{ order.address.name ? order.address.name : "" }}</span>
          <span>{{ order.address.mobile ? order.address.mobile : "" }}</span>
        </template>
        <template #label>
          {{ order.address.province ? order.address.province : "" }}
          {{ order.address.city ? order.address.city : "" }}
          {{ order.address.county ? order.address.county : "" }}
          {{ order.address.street ? order.address.street : order.address.address }}
        </template>
      </van-cell>
    </div>
    <div class="goodsmain">
      <div class="goods_list" v-if="order.goods && order.goods.length > 0">
        <van-card
          v-for="(item, index) in order.goods"
          :key="index"
          :num="item.num ? item.num : ''"
          :price="item.price ? item.price : ''"
          :title="item.goodstitle ? item.goodstitle : ''"
          :thumb="item.thumb ? item.thumb : ''"
          lazy-load
          @click="goodspage"
        >
          <template #desc>
            <div>
              {{item.optiontitle ? item.optiontitle : ''}}
              <van-tag color="#9168ed" text-color="#fff" v-if="item.yushouTime">{{item.yushouTime}}</van-tag>
            </div>
            <div v-if="item.jifen && parseFloat(item.jifen) > 0">
              <van-tag plain type="danger" size="mini">赠送：{{item.jifen}}积分</van-tag>
            </div>
          </template>
          <template #price-top>
            
          </template>
          <template #tag>
            {{strId}}
          </template>
          <template #footer>
            <div class="buyer_remark" v-if="item.message">
              <van-cell>
                <template #title>
                  买家备注：
                </template>
                <template #default>
                  {{item.message}}
                </template>
              </van-cell>
            </div>
          </template>
        </van-card>
        <van-cell class="order_btn" title>
          <template #right-icon>
            <van-button v-if="order.status > 0 && order.status < 2" @click="cuidan(order.ordersn)" size="small" plain hairline>催单</van-button>
            <!-- 实体商品订单退款按钮 -->
            <van-button size="small" v-if="order.is_service_btn" @click.stop="reFund">
              {{order.is_service_btn}}
            </van-button>
            <van-button size="small" v-if="order.is_service_btn2" @click.stop="cancelRefund">
              {{order.is_service_btn2}}
            </van-button>
            <van-button
              size="small"
              plain
              hairline
              @click.stop="reFundHistory(order.id)"
              v-if="order.bc_list_btn == 1"
            >退款记录</van-button>
            <van-button
              size="small"
              plain
              hairline
              @click.stop="miniTuikuan(order.id)"
              v-if="order.bc_go_btn == 1"
            >申请退款</van-button>
            <van-button
              size="small"
              v-if="order.bc_go_btn == 2"
              plain
              hairline
              @click="reFund"
            >{{order.is_saleservice > 0 && order.is_saleservice != 2 ? '售后中' : '申请售后'}}</van-button>
            <van-button
              size="small"
              type="danger"
              plain
              hairline
              @click="againBuy()"
            >
              再来一单
            </van-button>
            <van-button size="small" v-if="order.status == 0" @click="toPay()">
              去付款
            </van-button>
            <van-button
              size="small"
              v-if="order.status == 0 && false"
              @click="cancelPay()"
            >
              关闭订单
            </van-button>
          </template>
        </van-cell>
        <van-cell class="total_price" :border="false">
          <template #title>
            <div v-if="order.enoughnumsprice && parseFloat(order.enoughnumsprice) > 0">满减：&yen;<span class="red">{{order.enoughnumsprice}}</span></div>
            <div>
              实付：
              <span class="small">&yen;</span>
              <span class="red">{{ order.totalprice }}</span>
              ({{
                order.dispatchprice && parseFloat(order.dispatchprice) > 0
                  ? "含运费：" + order.dispatchprice + "元"
                  : "免运费"
              }})
            </div>
          </template>
        </van-cell>
      </div>
    </div>
    <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
      <van-button
        block
        icon="service-o"
        @click="qiyukefu"
      >
        联系客服
      </van-button>
    </div>
    <div class="salseseriver" v-if="order.check_status == 2 || order.refundstate > 0 || order.is_saleservice != 2">
      <van-cell v-if="order.check_status == 2">
        <template #title>
          请退款
        </template>
        <template #default>
          {{order.special_remark}}
        </template>
      </van-cell>
      <van-cell
        v-if="order.is_saleservice_content && order.is_saleservice_content.length > 0"
        class="shouH_cell red"
        @click="serviceDetail"
        is-link
      >
        <template #title >
          <div class="tuikuan_msg">
            售后内容：
          </div>
        </template>
        <template #default>
          {{order.is_saleservice_content}}
        </template>
      </van-cell>
    </div>
    
    <div class="orderinfo">
      <van-cell-group>
        <van-cell
          title="订单编号："
          v-if="order.ordersn"
          :value="order.ordersn"
        >
          <template #right-icon>
            <input type="text" v-model="order.ordersn" v-show="false" />
            <van-button
              v-clipboard:copy="order.ordersn"
              v-clipboard:success="ordersnonCopy"
              v-clipboard:error="ordersnonError"
            >
              复制
            </van-button>
          </template>
        </van-cell>
        <van-cell
          title="支付方式："
          v-if="order.ordersn"
          :value="order.paytype_str"
        />
        <van-cell
          title="物流公司："
          v-if="order.expresscompany"
          :value="order.expresscompany"
        />
        <van-cell
          title="快递单号："
          v-if="order.expresssn"
          :value="order.expresssn"
        >
          <template #right-icon>
            <input type="text" v-model="order.expresssn" v-show="false" />
            <van-button
              v-clipboard:copy="order.expresssn"
              v-clipboard:success="expressnonCopy"
              v-clipboard:error="expressnError"
            >
              复制
            </van-button>
          </template>
        </van-cell>
        <van-cell
          title="下单时间："
          v-if="order.createtime"
          :value="order.createtime"
        />
        <van-cell
          title="支付时间："
          v-if="order.paytime"
          :value="order.paytime"
        />
        <van-cell
          title="发货时间："
          v-if="order.sendtime"
          :value="order.sendtime"
        />
        <van-cell
          title="完成时间："
          v-if="order.completetime"
          :value="order.completetime"
        />
      </van-cell-group>
    </div>
    <div class="postage" v-if="order.postage && order.postage.length > 0">
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(item, index) in order.postage" :key="index">
          <h3 v-if="item.status">{{ item.status }}</h3>
          <p v-if="item.time">{{ item.time }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderDetails",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      ordertitle: "", // 标题
      orderid: "", // 订单id
      orderstatus: "", //  订单状态
      order: {},
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      userinfo: {},
      showkefu: 0,
    };
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID");
    this.ordertitle = this.$route.query.title;
    this.orderid = this.$route.query.id;
    this.orderstatus = this.$route.query.status;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "订单详情");
    this.init();
    setTimeout(() => {
      this.showkefu = localStorage.getItem("DK_SHOWKEFU");
    }, 500);
    
  },
  methods: {
    init() {
      console.log("订单详情");
      this.getorderinfo();
      this.getuserinfo();
    },
    // 获取订单信息
    getorderinfo() {
      let _that = null;
      _that = this;
      let UID = null;
      UID = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/detail",
          _that.$qs.stringify({
            id: _that.orderid
          })
        )
        .then(response => {
          
          if (response && response.data && response.data.code == 100000) {
            _that.order = response.data.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 获取用户信息
    getuserinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data && response.data.id > 0) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 复制订单号
    ordersnonCopy() {
      this.$toast("复制成功");
    },
    // 订单号复制失败
    ordersnonError() {
      this.$toast("复制失败");
    },
    // 复制快递单号
    expressnonCopy() {
      this.$toast("复制成功");
    },
    // 快递单号复制失败
    expressnError() {
      this.$toast("复制失败");
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    // 再次购买
    againBuy() {
      // let goods = null;
      // goods = {
      //   goodsId: this.order.goods[0].goodsid,
      //   selectedNum: this.order.goods[0].num,
      //   selectedSkuComb: {
      //     id: this.order.goods[0].optionid,
      //     "stock_num": this.order.goods[0].stock
      //   },
      //   shareid: this.shareid,
      //   merchid: this.merchid
      // };
      // this.$router.push({ name: "Payment", query: goods });
      this.$router.push({ name: "Details", query: { id: this.order.goods[0].goodsid, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },

    // 催单
    cuidan(ordersn) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/reminderOrder",
          _that.$qs.stringify({
            ordersn: ordersn
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100001) {
            _that.$toast(res.data.msg ? res.data.msg : '请重新登陆')
          }else {
            _that.$dialog.alert({
              title: res.data.title ? res.data.title : "",
              message: res.data.msg ? res.data.msg : "",
              theme: 'round-button',
            }).then(() => {
              // on close
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    serviceDetail() {
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: this.order.id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },

    // 产品详情页
    goodspage(){

      this.$router.push({ name: "Details", query: { id: this.order.goods[0].goodsid, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 申请退款
    reFund() {
      if ((this.order.status==1 && (this.order["is_outorder"] == 0 || this.order["is_outorder"] == 3)) || this.order["check_status"] == 2) {
        this.$router.push({
          name: "OrderRefund",
          query: {
            id: this.order.id,
            money: this.order.totalprice,
            status: this.order.status ? this.order.status : this.orderstatus,
            shareid: this.shareid,
            merchid: this.merchid
          } 
        });
      }else {

        if (this.order["is_saleservice"] > 0 && this.order["is_saleservice"] != 2) {
          this.$router.push({
            name: "serviceDetail",
            query: {
              id: this.order.id,
              shareid: this.shareid,
              merchid: this.merchid
            } 
          });
        }else {
          if(this.order["check_status"] == 2) {
            this.$router.push({
              name: "miniTuikuan",
              query: {
                id: this.order.id,
                shareid: this.shareid,
                merchid: this.merchid
              }
            })
          }else {
            this.$router.push({
              name: "RefundOrderOne",
              query: {
                id: this.order.id,
                shareid: this.shareid,
                merchid: this.merchid
              } 
            });
          }
        }
        // this.$router.push({
        //   name: "serviceDetail",
        //   query: {
        //     id: this.order.id,
        //     shareid: this.shareid,
        //     merchid: this.merchid
        //   } 
        // });
      }
      
    },

    // 退款记录
    reFundHistory(id) {
      this.$router.push({
        name: "refundHistory",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    // 申请退款
    miniTuikuan(id) {
      this.$router.push({
        name: "miniTuikuan",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    
    // 撤销退款申请
    cancelRefund() {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/cancel_refund",
          _that.$qs.stringify({
            orderid: _that.order.id,
            refundid: _that.order.refundid
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "撤销成功");
            _that.$router.go(0); // 刷新当前页
          }else{
            _that.$toast(res.data.msg ? res.data.msg : "撤销失败");
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 去付款
    toPay() {
      console.log("去付款");
      let uid = 0; // 用户id
      let ordersn = ""; // 订单编号
      let money = 0; // 订单金额
      let orderid = 0; // 订单id
      uid = this.order.memberid;
      ordersn = this.order.ordersn;
      money = this.order.totalprice;
      orderid = this.order.id;
      localStorage.setItem("ordersn", ordersn);
      localStorage.setItem("money", money);
      localStorage.setItem("orderid", orderid);
      this.$router.push({name: "CheckPayment", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 取消订单(关闭订单)
    cancelPay() {
      console.log("取消订单");
      let _that = null;
      _that = this;
      this.$dialog.alert({
        title: "关闭订单",
        message: "确定要关闭订单?",
        showCancelButton: true,
        beforeClose: function(action, done) {
          done();
          if (action == "confirm") {
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/cancel",
                _that.$qs.stringify({
                  id: _that.order.id
                })
              )
              .then(response => {
                console.log(response);
                if (response && response.data && response.data.code == 100000) {
                  console.log("取消订单成功");
                  _that.$toast(response.data.msg);
                  _that.getorderinfo();
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (action == "cancel") {
            console.log("取消按钮");
          }
        }
      });
    },
    // 七鱼客服
    qiyukefu() {
      let _that = null;
      _that = this;
      if ((_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.userinfo && _that.userinfo.id > 0) {
          console.log(_that.userinfo);
          if (_that.userinfo.level == '6') {
            level = 2;
          }else if (_that.userinfo.level == '1') {
            level = 3;
          }else if (_that.userinfo.level == '14') {
            level = 4;
          }else if (_that.userinfo.level == '5') {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        }else{
          _that.$toast("请先登录");
          return false;
        }

        if(_that.userinfo.id > 0) {
          let str = "";
          let desc = "";
          str = JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":4, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":5, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid}
          ]);
          desc = JSON.stringify([{title: _that.order.ordersn + "【" + _that.order.surplus.time_str + "】"},{picture: _that.order.goods[0].thumb}]);
          window.location.href = "https://kefu.midbest.cn/wap/?uid=" + _that.userinfo.id + "&sessionform=" + str + "&card=" + desc + "&fromType=2";
          return false;
        }
      }else{
        this.$toast("页面还未加载完，稍等一下");
      }
    }
  }
};
</script>
<style lang="less">
.orderdetails {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #ededed;
  padding-bottom: 60px;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  // 地址
  .address {
    text-align: left;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
      }
      .van-icon {
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }
  // 产品
  .goodsmain {
    margin-top: 10px;
    text-align: left;
    overflow: hidden;
    background-color: #fff;
    padding-top: 8px;
    .van-card:not(:first-child){
      margin-top: 0;
    }
    .van-card__tag {
      top: inherit;
      left: inherit;
      bottom: 0;
      right: 0;
      background-color: #fff;
      color: #808080;
      font-size: 14px;
      line-height: 16px;
      padding: 0 2px;
      opacity: 0.5;
    }
    // 退款按钮
    .siglegoodsBtn{
      padding: 5px 0;
      text-align: right;
      .van-button {
        margin-left: 10px;
        height: 26px;
      }
    }
    // 买家备注
    .buyer_remark{
      .van-cell{
        padding: 10px 0 0;
        line-height: 20px;
        .van-cell__value{
          flex: 1;
          text-align: left;
        }
      }
    }
    .order_btn {
      text-align: right;
      .van-cell__title {
        flex: 1;
      }
      .van-button {
        margin-left: 10px;
      }
    }
    .total_price {
      text-align: right;
      .van-cell__title {
        flex: 1;
      }
    }
    .van-card {
      background-color: #fff;
      .van-card__thumb img {
        border-radius: 4px;
      }
    }
    .red {
      color: #ff3b30;
    }
  }
  // 售后/驳回原因
  .salseseriver{
    overflow: hidden;
    margin-top: 10px;
    background-color: #fff;
    .van-cell{
      padding: 10px 16px;
      .van-cell__title {
        width: 80px;
        text-align: left;
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        color: #ff0000;
      }
    }
  }
  .shouH_cell {
    .serviceDetail {
      width: 80px;
    }
    .van-cell__value {
      flex: 1;
    }
  }
  // 订单
  .orderinfo {
    overflow: hidden;
    margin-top: 10px;
    background-color: #fff;
    .van-cell-group {
      .van-cell {
        .van-cell__title {
          width: 80px;
          text-align: left;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          margin-right: 5px;
        }
        .van-button {
          padding: 0 10px;
          font-size: 12px;
          height: 24px;
          line-height: 22px;
        }
      }
    }
  }
  // 物流信息
  .postage {
    margin-top: 10px;
    background-color: #fff;
    overflow: hidden;
    text-align: left;
    padding-bottom: 10px;
    .van-step__title {
      h3,
      p {
        margin: 0;
        line-height: 24px;
        font-size: 14px;
      }
    }
  }
}
</style>